<template>
    <div>
        .... hang on ...
    </div>

</template>
<script>
export default {
    data(){
        return {

        }
    },
    mounted(){
        let urlParams = new URLSearchParams(window.location.search);
        console.log(urlParams.has('yourParam')); // true
        console.log(urlParams.get('yourParam')); // "MyParam"
    }
}
</script>